/* PROJECT VARIABLES */

$black: #080a0a;
$yellow: #EFB319;
$light-yellow: #FFEAB4;
$red: #EA7B7B;
$light-red: #FFC0C0;
$lime: #86CD5D;
$light-lime: #D4FFBB;
$grey: #A8A8A8;
$light: #F0EBE9;
$dark-green: #01291B;
$green: #034930;
$light-green: #3BCC99;
$brown: #34231F;

$xl-space: 48px;
$lg-space: 32px;
$md-space: 16px;
$sm-space: 8px;

$mobile-width: 640px;
$tablet-width: 1024px;

$wrapper-width: 1440px;

$lg-radius: $xl-space;
$radius: $md-space;

$transition: .2s ease-in-out;

$font-family: 'Poppins';