@import '../../../variables.scss';

.auth-page {
  position: relative;
  background-color: $light;
  overflow: hidden;
  width: 100dvw;
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    content: '';
    position: absolute;
    top: -50%;
    height: 100dvh;
    left: -50%;
    width: 200dvw;
    background-color: $dark-green;
    box-shadow: 0 30px 0 rgba($dark-green, .1);
    border-radius: 50%;
  }

  .auth-main-content {
    position: relative;
    z-index: 1;

    .auth-logo {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: #FFF;
      margin-bottom: $lg-space;
      margin-top: calc((95px + $lg-space) * -1);
      justify-content: center;

      .company-name {
        border-left: 1px solid #ffffff2a;
        padding: $md-space 0 $md-space $lg-space;
        font-size: 1.3rem;
        font-weight: 100;
      }

      img {
        max-width: 280px;
      }
    }

    .auth-card {
      display: flex;
      background-color: #ffffff;
      border-radius: $radius;
      box-shadow: 0 4px 8px #00000021;
      width: 720px;
      max-width: 100%;
      aspect-ratio: 2/1;

      .auth-image {
        width: calc(100% / 3);
        overflow: hidden;
        border-radius: $radius 0 0 $radius;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .auth-page-content {
        width: calc(100% / 3 * 2);
        padding: $xl-space;
        position: relative;
      }
    }
  }
}

@media(max-width: 768px) {
  .auth-page {

    &::after {
    }

    .auth-main-content {

      .auth-logo {
        position: absolute;
        top: 4rem;
        .company-name {

        }

        img {

        }
      }

      .auth-card {
        display: flex;
        justify-content: center;
        width: 490px;
        height: 528px;

        .auth-image {
          display: none;

          img {
          }
        }

        .auth-page-content {
          padding: 2rem;
          padding-top: 4rem;
          width: 100%;
        }
      }
    }
  }
}

@media(max-width: 500px) {
  .auth-page {

    &::after {
    }

    .auth-main-content {

      .auth-logo {
        margin-bottom: 0;
        .company-name {

        }

        img {

        }
      }

      .auth-card {
        width: 100vw;
        height: 100vh;
        border-radius: 0;

        .auth-image {

          img {
          }
        }

        .auth-page-content {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}