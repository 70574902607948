@import '../../variables.scss';

.Login {
  display: flex;
  flex-direction: column;
  height: 100%;

  h1 {
    font-weight: 600;
    font-size: 1.8rem;
    margin-top: 0;
    color: $dark-green;
  }

  .form {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: $md-space;

    .fields {
      display: flex;
      gap: $sm-space;
      align-items: flex-end;
    }

    .forgotLink {
      font-weight: 600;
    }
  }

  .cadastro,
  .cadastro a {
    margin-top: auto;
    font-size: .8rem;
    color: $grey;
  }

  .cadastro a{
    font-weight: 600;
  }
}

@media screen and (max-width: 768px) {
  .Login {
    // Estilos para .Login
    width: 100%;

    h1 {
      // Estilos para h1
      font-size: 2.3rem;
    }

    .form {
      // Estilos para .form
      margin-top: 2rem;

      .fields {
        // Estilos para .fields
        margin-top: 1.2rem;
        display: flex;
        flex-direction: column;
        align-items: end;

        .field-wrapper {
          width: 100%;
        
          input{
            height: 3.5rem;
            font-size: 1rem;
          }
        }
        button{
          margin-top: 1rem;
          width: 5rem;
        }
      }

      .forgotLink {
        text-align: start;
      }
    }

    .cadastro{
      text-align: end;
      font-size: 1rem;

    }
    .cadastro a {
      // Estilos para .cadastro e .cadastro a
      color: $light-green;
      font-size: 1rem; 
    }
  }
}

@media screen and (max-width: 500px) {
  .Login {
    // Estilos para .Login
    

    h1 {
      // Estilos para h1
      
    }

    .form {
      // Estilos para .form
      

      .fields {
        // Estilos para .fields
        

        .field-wrapper {
         
        
          input{
            
          }
        }
        button{
        }
      }

      .forgotLink {
      }
    }

    .cadastro{
     

    }
    .cadastro a {
      // Estilos para .cadastro e .cadastro a
      
    }
  }
}