@import '../../variables.scss';

// ------------------------------
// Menu Component Styles
// ------------------------------

.menu {
  max-width: $wrapper-width;
  width: 80dvw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $lg-space 0;
  margin: 0 auto;

  @media screen and (max-width: 1280px) {
    width: 100vw;
    padding: $md-space 0;
  }

  @media screen and (max-width: 768px){
    padding: $md-space;
  }

  @media screen and (max-width: 480px){
    padding: $md-space 0;

    .menu-logo{
      .company-name{
        font-size: 1rem;
      }
      img{
        width: 12rem;
      }
    }
  }

  // ------------------------------
  // Logo Styles
  // ------------------------------

  .menu-logo {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #FFF;
    justify-content: center;

    .company-name {
      border-left: 1px solid #ffffff2a;
      padding: $md-space 0 $md-space $md-space;
      font-size: 1rem;
      font-weight: 100;
    }

    img {
      max-width: 200px;
    }
  }

  // ------------------------------
  // Consulta Recebíveis Button
  // ------------------------------

  .consulta-recebiveis {
    display: flex;
    cursor: pointer;
    color: $dark-green;
    background-color: #FFF;
    padding: 0.5rem 1rem;
    border-radius: 4rem;
    transition: .2s;
    margin: auto;
    margin-right: 1rem;

    .icon {
      margin-left: .5rem;
    }

    &:hover {
      transform: scale(1.05);
    }
  }

  // ------------------------------
  // Open Menu Button
  // ------------------------------

  .open-menu-button {
    display: none;

    @media (max-width: 1024px) {
      display: flex;
      margin-right: 1rem;

      svg {
        height: 1.5rem;
        width: 1.5rem;
      }
    }
  }

  // ------------------------------
  // Menu List Styles
  // ------------------------------

  ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    padding: 0;
    gap: $sm-space;
    margin: auto;

    .pages-menu {
      display: flex;
      li {
        .icon {
          margin-left: .5rem;
        }

        .menu-item-link {
          text-decoration: none;
          color: #FFF;
          padding: $md-space;
          font-size: .9rem;
          opacity: .5;
          border-bottom: 3px solid transparent;
          transition: $transition;
        }

        &.menu-active,
        &:hover {
          .menu-item-link {
            opacity: 1;
            border-color: #FFF;
          }
        }
      }
    }
  }

  // ------------------------------
  // Responsive Styles
  // ------------------------------

  @media (max-width: 1024px) {
    .interactive {
      display: flex;
      align-items: center;

      ul {
        transform: translateX(100%);
        display: none;
        animation-duration: .4s;
        animation-fill-mode: forwards;
      }

      &.open ul {
        display: flex;
        animation: slideIn .4s forwards;
      }

      &.closing ul {
        display: flex;
        animation: slideOut .4s forwards;
      }

      &.open ul,
      &.closing ul {
        position: fixed;
        right: 0;
        top: 0;
        z-index: 10;
        background-color: $dark-green;
        flex-direction: column;
        height: 100dvh;
        width: 20rem;
        justify-content: start;
        align-items: end;
        padding: 1.2rem;
        gap: 1.5rem;

        box-shadow: 0 4px 20px rgba(0, 0, 0, 1);

        .pages-menu {
          order: -1;
          display: block;

          li {
            margin: .5rem 0;
            text-align: end;

            .menu-item-link {
              padding: 0;
              font-size: 1.2rem;
            }

            &.menu-active,
            &:hover {
              .menu-item-link {
                opacity: 1;
                border-color: transparent;
              }
            }
          }
        }

        .userMenu {
          order: -2;
          margin: 1rem 0;
          font-size: 1.2rem;
        }
      }

      .consulta-recebiveis {
        margin: 0;
      }
    }
  }


  @media (max-width: 768px){

  }
}

// ------------------------------
// Keyframe Animations
// ------------------------------

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(100%);
  }
}