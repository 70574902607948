.flex {
    display: flex;
}

.containerNumberLabel {
    gap: 2rem;

    display: flex;
    flex-wrap: wrap;

    .numberLabel{
        min-width: 12rem;
    }
}

.faturamento-component {
    width: 100%;
}

.filters-faturamento {
    margin-bottom: 1rem;
    gap: 1rem;
    flex-wrap: wrap;
}

.header-faturamento {
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 1rem;
}

p {
    margin: 0;
    font-size: x-small;
    color: lightgray;
}