@import '../../variables.scss';

.float-button {
  position: fixed;
  bottom: $md-space;
  right: $md-space;
  z-index: 999;
  font-size: 1.8rem;
  color: #FFF;
  background: $green;
  height: 64px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
  padding: 0 $lg-space;
  box-shadow: 0 8px 16px rgb(0 0 0 / 20%);

  .float-button-text {
    font-size: 1rem;
    margin-left: $sm-space;
  }
}