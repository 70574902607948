@import '../../../variables.scss';

.userMenu {
  color: #FFF;
  margin-left: $lg-space;
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-right: 1.5rem;

  .anticon {
    width: 32px;
    height: 32px;
    border: 1px solid #FFF;
    border-radius: 50%;
    margin-left: $sm-space;
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 1024px) {
  .userMenu {
    margin: 0;
    height: fit-content;

    .anticon {
      margin-left: 1rem;
      height: 2.5rem;
      width: 2.5rem;
    }
  }
}