@import '../../variables.scss';

.Dashboard {
  .page-content {
    .metric-group {
      padding: 2rem;
      transition: $transition;
      border: solid 1px #ececec;
      border-radius: $radius;

      @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
      }
    }

    .metric-group:hover {
      background-color: #ffffff;
      border: solid 1px transparent;
      box-shadow: 12px 12px 24px #e0e0e0,
        -12px -12px 24px #ffffff;
    }

    .red {
      --fontColor: #EA7B7B;
    }

    .orange {
      --fontColor: #e9b470;
    }

    .blue {
      --fontColor: #63afec;
    }

    .green {
      --fontColor: #3BCC99;
    }

    .numberLabel-col {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      column-gap: $xl-space;
      row-gap: $sm-space;

      h3 {
        width: 100%;
      }
    }

    .numberLabel {
      border-left: 3px solid var(--fontColor);
      padding-left: $md-space;

      .number {
        color: var(--fontColor);
        font-weight: 600;
        font-size: 1.5rem;
      }
    }

    .row {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      column-gap: $lg-space;
      row-gap: $lg-space;
      width: 100%;

      .col-6 {
        width: 100%;
        max-width: calc(100% * (6 / 12) - $lg-space);

        @media screen and (max-width: 768px) {
          max-width: 100%;
        }
      }

      .col-4 {
        width: 100%;
        max-width: calc(100% * (4 / 12) - $lg-space);
      }
    }

    .ringChart {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: $sm-space;

      @media screen and (max-width: 768px) {
        justify-content: center;
        gap: 5%;
      }

      @media screen and (max-width: 480px) {
        flex-direction: column;
      }


      &+div {
        margin-top: $sm-space;
      }

      &-labels {
        min-width: 100px;
        display: flex;
        flex-direction: column;
        gap: $md-space;

        @media screen and (max-width: 480px) {
          flex-direction: row;
          justify-content: center;
          gap: 4rem;
        }

        @media screen and (max-width: 425px) {
          gap: 2rem;
        }

        @media screen and (max-width: 375px) {
          flex-direction: column;
          gap: 0;
        }

        & .ringChart-label-item {
          --color: #F2F2F2;
          border-left: 3px solid var(--color);
          padding-left: $md-space;
          margin: .5rem 0;
          width: 7rem;

          @media screen and (max-width: 375px) {
            width: 80vw;
            display: flex;
            justify-content: space-between;

            .amount{
              gap: 2rem;
              display: flex;
            }
          }

          h5 {
            margin: 0;
          }

          .amount {
            font-size: 1.2em;
          }

          &.green {
            --color: #3BCC99;
          }

          &.red {
            --color: #EA7B7B;
          }

          .MELHOR {
            color: #3BCC99;
          }

          .PIOR {
            color: #EA7B7B;
          }
        }
      }

      &-chart {
        position: relative;
        width: 100%;
        max-width: 400px;
        height: 300px;

        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 1024px) {
          width: 250px;
        }
        
        @media screen and (max-width: 768px) {
          width: 350px;
        }

        h3 {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 14rem;
          transform: translate(-50%, -50%);
          text-align: center;
          margin: 0;

          @media screen and (max-width: 1024px) {
            font-size: 1rem;
            width: 12rem;
          }
        }

        canvas {
          max-height: 320px !important;
          max-width: 320px !important;
        }
      }
    }
  }
}

.standby {
  display: none !important;
  visibility: hidden;
  opacity: 0;
}