@import '../../../variables.scss';

.novo-cliente {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  margin-bottom: 1rem;
  
  .form-section {
    display: flex;
    flex-wrap: wrap;
    
    background-color: #fff;
    border: 1px solid #ececec;
    border-radius: $radius;
    padding: 1rem;
    
    h4 {
      width: 100%;
    }
    .row {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: $sm-space;
      
      .field-content {
        display: flex;
        
        #phone_prefix {
          width: 60px;
        }
      }
    }
  }
}