@import '../../variables.scss';

.pointer {
    cursor: pointer;
}

@media screen and (max-width: 1024px) {
    .ant-table-content {
        width: 100%;

        table {

            col:nth-child(5),
            th:nth-child(5),
            td:nth-child(5) {
                display: none;
            }

            .ant-table-tbody {
                width: 100%;

                .ant-table-row {
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 620px) {
    .ant-table-content {
        table {

            col:nth-child(6),
            th:nth-child(6),
            td:nth-child(6) {
                display: none;
            }
        }
    }
}

@media screen and (max-width: 560px) {
    .ant-table-content {
        table {
            colgroup{
                display: none;
                col:nth-child(1){
                    display: block;
                }
            }
            
            .ant-table-thead {
                display: none;
            }

            .ant-table-tbody {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                row-gap: 1rem;
                column-gap: 2%;
                .ant-table-row {
                    padding: .5rem;
                    width: 49%;
                    display: flex;
                    flex-wrap: wrap;

                    background-color: #fff;
                    border-radius: .75rem;
                    border: solid 1px #ececec;

                    .ant-table-cell {
                        width: 100%;
                    }
                }
            }
        }
    }
}