@import '../../../variables.scss';

.error-txt {
  color: #ff4242;
  font-size: .7rem;
}

.error-list {
  margin-top: -10px;
  padding: 5px 0 0 15px;
  color: #ff4242;
  font-size: .7rem;
}