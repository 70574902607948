@import '../../../../variables.scss';

.share-fatura {
  display: flex;
  flex-wrap: wrap;
  gap: $sm-space;
  border-top: 1px solid #eee;
  padding-top: $md-space;

  .section-title {
    width: 100%;
  }
  a {
    text-decoration: none;

    button {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: #ffffff;
      border: 1px solid $dark-green;
      height: $lg-space*3;
      width: $lg-space*3;
      font-size: .8rem;

      .ant-btn-icon {
        font-size: 1.8rem;
        text-align: center;
        margin-bottom: $sm-space;
        margin-inline-end: 0 !important;
      }

      &:hover {
        background-color: $dark-green !important;
        color: #FFF !important;
      }
    }

    &:hover {
      button {
        background-color: $dark-green;
        color: #FFF;
      }
    }
  }
}