@import '../../variables.scss';

.drawer-form {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  gap: $md-space;

  .form {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &-section {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;

      width: 100%;

      background-color: #fff;
      border: 1px solid #ececec;
      border-radius: $radius;
      padding: 1rem;

      &-title {
        color: $dark-green;
        width: 100%;
        margin-top: 0;
        font-size: 1rem;
      }

      .field-wrapper {
        flex: 1;
        margin: .25rem;
        min-width: 10rem;

      }
    }

    &+.form-footer {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin: $md-space 0;
    }
  }
}