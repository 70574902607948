@import '../../../variables.scss';

.switch-section {
  margin: $md-space 0;

  .switch-wrapper {
    display: flex;
    align-items: center;
    gap: $md-space;

    button{
      max-width: 2rem;
    }
  }
}

.form-footer {
  .total-display {
    &-label {
      font-size: 1rem;
      color: $grey;
    }
    &-amount {
      font-size: 1.8rem;
      color: $dark-green;
      font-weight: bold;
    }
  }
}


// Estilos para remover o acinzentado dos campos desabilitados
input[disabled],
textarea[disabled],
select[disabled],
.ant-input[disabled],
.ant-select-disabled .ant-select-selector,
.ant-picker-disabled,
.ant-input-number-disabled .ant-input-number-input {
  background-color: #fff !important;  // Ou qualquer outra cor que você prefira
  color: $black !important;  // Ou qualquer outra cor de texto que você prefira
  opacity: 1;  // Remove a opacidade que geralmente torna o campo acinzentado
  cursor: not-allowed;  // Mantém o cursor de "não permitido" para indicar que o campo não é editável

  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

}

// Estilos para manter os switches não acinzentados quando desabilitados
.ant-switch-disabled {
  opacity: 1;
}

// Manter o campo de entrada não acinzentado, mas desativado
.ant-input-number[disabled] .ant-input-number-input {
  background-color: #fff;
  color: $black;
}

// Remover ícones dos componentes desabilitados
.ant-picker-disabled .ant-picker-clear,
.ant-picker-disabled .ant-picker-suffix,
.ant-select-disabled .ant-select-arrow {
  display: none; // Oculta os ícones
}

// Remover ícones dos campos de Select desabilitados
.ant-select-disabled .ant-select-selection-item,
.ant-select-disabled .ant-select-selector {
  background-image: none; // Remove a seta de seleção padrão
}
