@import '../../../../variables.scss';

.add-item-btn {
  margin-top: $sm-space;
}

.dynamic-form {
  width: 100%;
  position: relative;

  .form-section {
    border-top: 1px solid $light;
    padding-top: $md-space;
    margin-top: $md-space;
    margin-top: 0;

    background-color: #fff;
    border: 1px solid #ececec;
    border-radius: $radius;
    padding: 1rem;

    .form-section-title {
      margin-top: 0;
    }

    .row {
      display: flex;
      flex-wrap: wrap;
    }

    .desc-add {
      width: 100%;

      button{
        margin-top: .5rem;
      }
    }
  }


  .field-flex {
    min-width: 100%;
  }
}

@media screen and (max-width: 480px) {
  button {
    width: 100%;
  }
}

// button[disabled].ant-btn{
//   display: none;
// }
