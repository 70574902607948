@import '../../variables.scss';

.field-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: $md-space;

  @media screen and (max-width: 480px){
    width: 100%;
  }

  .field-label {
    text-transform: uppercase;
    font-size: 0.7rem;
    font-weight: 500;
    letter-spacing: 1px;
    color: $grey;
    margin-bottom: $sm-space;
    
  }

  
}
.field-error {
  color: $red;
  font-size: 10px;
  position: absolute;
  bottom: -16px;
}