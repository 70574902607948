@import '../../../variables.scss';

.ClienteItem {
  .page-title {
    margin-bottom: $lg-space;

    @media screen and (max-width: 1024px) {
      margin-left: 3rem;
    }

    @media screen and (max-width: 768px) {
      font-size: 1.8rem;
      margin-bottom: $sm-space;
      margin-left: 3.5rem;
    }
  }

  .drawer-form {
    padding: 0;
    max-width: 720px;
  }
}