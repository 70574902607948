.filtro-tipo{
    display: flex;
    flex-direction: column;
    justify-content: center;

    margin-bottom: 2rem;
}

.header-emitido-recebido{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}