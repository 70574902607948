@import './variables.scss';

body,
html {
  margin: 0;
  font-family: $font-family, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  color: $black;
  background-color: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  display: flex;
  justify-content: center;
}

*,
:root {
  box-sizing: border-box;
}

a {
  color: $light-green;
}

.ant-btn {
  box-shadow: none !important;
}

.field-content>input,
.ant-input,
.ant-input-number,
.ant-picker,
.ant-select .ant-select-selector,
.ant-input-group-addon .ant-btn {
  border: 0 !important;
  border-radius: .1rem;
  transition: $transition;
  box-sizing: border-box;
  margin: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 1rem;
  line-height: 1.5;
  list-style: none;
  font-family: Poppins;
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  background-color: #ffffff;
}

.field-content{
    border-bottom: 1px solid $grey !important;

}

.ant-select {
  width: 100%;
}

.field-content>input:focus,
.ant-input:focus,
.ant-input-number:focus,
.ant-picker:focus,
.ant-select:focus .ant-select-selector,
.ant-input-group-addon .ant-btn:focus {
  box-shadow: none !important;
  border-bottom: 1px solid $dark-green !important;
}

.ant-input-group-addon .ant-btn {
  height: 28px !important;
}

.ant-select-item-option-content {
  font-size: 1rem;
}

.ant-picker {
  display: inline-flex;

  & .ant-picker-input>input {
    font-size: 1rem;
  }
}

.ant-table {
  &-thead {
    tr {
      th.ant-table-cell {
        background-color: transparent;
        text-transform: uppercase;
        color: $grey;
        font-weight: 500;
        font-size: .7rem;
        letter-spacing: 1px;
      }
    }
  }

  &-tbody {
    font-size: .9rem;

    .ant-table-cell {
      position: relative;

      .col-name {
        text-transform: capitalize;
      }

      .col-status {
        text-transform: uppercase;
        letter-spacing: 1px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 16px 16px;
        border-left: 4px solid #eee;
        display: flex;
        align-items: center;

        &.paid,
        &.externally_paid {
          color: $light-green;
          border-color: $light-green;
        }

        &.in_analysis,
        &.authorized,
        &.refunded {
          color: $green;
          border-color: $green;
        }

        &.pending,
        &.partially_paid {
          color: $yellow;
          border-color: $yellow;
        }

        &.canceled,
        &.in_protest,
        &.expired {
          color: $red;
          border-color: $red;
        }

        &.draft,
        &.chargeback {
          color: $grey;
          border-color: $grey;
        }
      }
    }
  }
}

.ant-drawer,
.ant-btn {
  font-size: inherit;

  .ant-drawer-body {
      @media (max-width: 480px) {
        padding: .5rem;
      }
  }
}

input:autofill,
input:-internal-autofill-selected,
input:-webkit-autofill:focus,
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #f2f7f7 inset;
}

:where(.css-dev-only-do-not-override-1nrxc7b).ant-table-wrapper .ant-table-pagination-right {
  justify-content: flex-start;
}



.field-flex {
  flex: 1;
  width: 100%;
}

.error {

  .field-content>input,
  .ant-input,
  .ant-input-number,
  .ant-picker,
  .ant-select .ant-select-selector,
  .ant-input-group-addon .ant-btn {
    border-bottom: 1px solid $red !important;
  }
}

::-webkit-scrollbar {
  width: 8px;
  /* Largura da barra de rolagem */
  height: 8px;
  /* Altura da barra de rolagem horizontal */
}

::-webkit-scrollbar-track {
  background: transparent;
  /* Cor do fundo da trilha */
  border-radius: 10px;
  /* Arredondamento dos cantos */
}

::-webkit-scrollbar-thumb {
  background: #888888e0;
  /* Cor do polegar */
  border-radius: 10px;
  /* Arredondamento dos cantos */
}

::-webkit-scrollbar-thumb:hover {
  background: #555555e0;
  /* Cor do polegar ao passar o mouse */
}