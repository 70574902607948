@import '../../../variables.scss';

.default-page {
  position: relative;
  background-color: $light;
  overflow-x: hidden;
  width: 100dvw;
  height: 100dvh;
  display: flex;
  justify-content: center;

  &::after {
    content: '';
    position: absolute;
    top: -70%;
    height: 100dvh;
    left: -50%;
    width: 200dvw;
    background-color: $dark-green;
    box-shadow: 0 30px 0 rgba($dark-green, .1);
    border-radius: 50%;
  }

  &-content {
    width: 100dvw;
    position: relative;
    z-index: 1;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 5vw;

    @media screen and (max-width: 1280px) {
      padding: 0;
    }
  }

  &-card {
    max-width: $wrapper-width;
    width: 80vw;
    min-width: calc(1024px + 2*($xl-space));
    border-radius: $radius;
    background-color: #ffffff;
    box-shadow: 0 4px 8px #00000021;
    padding: $lg-space $xl-space*1.5;
    margin: 0 auto $xl-space auto;
    position: relative;

    @media screen and (max-width: 1280px) {
      width: 100vw;
      padding: $md-space $xl-space;
      margin: 0 0 5rem 0;
      min-width: calc(960px + 2*($lg-space));
    }

    @media screen and (max-width: 1024px) {
      width: 90vw;
      min-width: 100%;
      padding: $md-space;
      box-shadow: none;
    }

    @media screen and (max-width: 768px) {
      width: 95vw;
      padding: $md-space;
      min-width: 95vw;
      margin: 0 auto;
    }

    @media screen and (max-width: 480px) {
      width: 100vw;
      padding: $sm-space;
      border-radius: 0;
    }

    .ant-btn.back-btn {
      border: 0;
      position: absolute;
      top: 60px;
      left: 20px;

      @media screen and (max-width: 1280px) {
        top: 45px;
        left: 5px;
      }

      @media screen and (max-width: 768px) {
        top: 40px;
        left: 10px;
      }

      @media screen and (max-width: 480px) {
        top: 30px;
        left: 10px;

        width: auto;
      }
    }

    .page-header {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      margin-bottom: $md-space;

      @media screen and (max-width: 620px) {
        flex-direction: column;
        align-items: flex-start;
      }

      .page-title {
        font-weight: 600;
        color: $dark-green;
        font-size: 2rem;

        @media screen and (max-width: 1024px) {
          margin-left: 3rem;
        }
        
        @media screen and (max-width: 768px) {
          font-size: 1.8rem;
          margin-bottom: $sm-space;
          margin-left: 3.5rem;
        }       
      }

      .page-title.home {
        margin-left: .5rem;
      }

      .page-actions {
        display: flex;
        justify-content: flex-end;
        column-gap: $sm-space;

        @media screen and (max-width: 480px) {
          margin-top: $sm-space;
          width: 100%;
          flex-direction: column;
          gap: $sm-space;
          padding: 0 1rem;
        }
      }
    }

    .filters {
      display: flex;
      column-gap: $md-space;
      flex-wrap: wrap;
      margin-bottom: $md-space;

      @media screen and (max-width: 768px) {
        column-gap: $sm-space;
      }

      @media screen and (max-width: 480px) {
        flex-direction: column;
        gap: $sm-space;
      }
    }
  }
}